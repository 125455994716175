
import './App.css';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Konami from 'react-konami-code';

import { getPhantomWallet, getSlopeWallet, getSolflareWallet, getSolletWallet } from '@solana/wallet-adapter-wallets';
import { useWallet, WalletProvider, ConnectionProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { CHALLENGES, CoinFlipStatus, constants, ERRORS, getOppositeSide } from './utils/constants';
import { enableMoreDegeneracy, initDegeneracy, getCurrentBalance, signMessage, goDegen, getRewards, rewardExists, getDegenBalance, getRewardsBalance, withdrawDegen } from './api-smart-contracts/dcf';
import { Dropdown, Button, Toast, ToastContainer, Popover, OverlayTrigger } from 'react-bootstrap';
import { getCoinFlip, getNonce, authorize } from './api/degen.service';
import { DateTime } from 'luxon';
import { confetti } from './confetti';
import { loadState, saveState } from './utils/localStorage';
import { queryLatestCoinFlips, queryTopCoinFlips } from './api/queries.service';
import { getProfile } from './api/profiles.service';
import { SocialLinks } from './components/SocialLink';
import { runWorkflow } from "./utils/workflow";
import createCoinFlipWorkflowSteps from "./workflows/createCoinFlip";
import { useMultiAudio } from './hooks/useAudio';
import { AudioButton } from './components/AudioButton';
import { FAQModal } from './components/modals/FAQModal';
import { SlowTPSModal } from './components/modals/SlowTPSModal';
import { ProfileModal } from './components/modals/ProfileModal';
import { ResponsibleModal } from './components/modals/ResponsibleModal';
import { FlipRow } from './components/FlipRow';
import { ResultPage } from './components/pages/ResultPage';
import { HowToPlayModal } from './components/modals/HowToPlayModal';
import { AcceptTermsModal } from './components/modals/AcceptTermsModal';
import { useInterval } from './hooks/useInterval';
import { PlayersCardModal } from './components/modals/PlayersCardModal';
import { createTokenAccount } from './api-smart-contracts/spl-token';
import { NotificationsModal } from './components/modals/NotificationsModal';
import { getWave1Eligibility } from './api/whitelists';
import Roomlio from './components/Roomlio';
import { TakeABreakModal } from './components/modals/TakeABreakModal';
import { StuckModal } from './components/modals/StuckModal';
import { CongratsModal } from './components/modals/CongratsModal';

const NEW_URL_MAP = {
  'degencoinflip.com': 'https://degencoinflip.com',
  'flip.degendex.com': 'https://degencoinflip.com/degendex',
  'flip.meerkatmillionaires.club': 'https://degencoinflip.com/mmcc',
  'flip.solnftradar.com': 'https://degencoinflip.com/solnftradar',
  'flip.thedogecapital.com': 'https://degencoinflip.com/dogecapital',
  'flip.solslugs.com': 'https://degencoinflip.com/solslugs',
  'tajfeet.io': 'https://degencoinflip.com/boryokudragonz',
  'flip.visionarystudios.io': 'https://degencoinflip.com/visionary',
  'doubleorskull.com': 'https://degencoinflip.com/electons',
  'www.doubleorskull.com': 'https://degencoinflip.com/electons',
  'sac.degencoinflip.com': 'https://degencoinflip.com/stonedapecrew',
  'flip.familysolnft.com': 'https://degencoinflip.com/familysol',
  'flip.generousrobots.com': 'https://degencoinflip.com/generousrobots',
  'flip.moshiheads.com': 'https://degencoinflip.com/moshiheads',
  'chikara.degencoinflip.com': 'https://degencoinflip.com/chikara',
  'flip.metafrogbusinessclub.com': 'https://degencoinflip.com/metafrog',
  'flip.angrybattleroyale.com': 'https://notdegencoinflip.com/angrydao',
  'degencoinflip-test.firebaseapp.com': 'https://notdegencoinflip.com/xyz',
  'ukraine.degencoinflip.com': 'https://degencoinflip.com?b=ukraine',
  'stats.degencoinflip.com': 'https://mixpanel.com/public/KQMKc744sGRZMq3idvsBmK',
  'stat.degencoinflip.com': 'https://mixpanel.com/public/KQMKc744sGRZMq3idvsBmK',
}

const CHALLENGE_INDEX = 0;
const CURRENT_CHALLENGE = CHALLENGES[CHALLENGE_INDEX];
const PRIMARY_THEME = CURRENT_CHALLENGE.themes[0];
const SECONDARY_THEME = CURRENT_CHALLENGE.themes[1];
const DEFAULT_PFP = "https://i.imgur.com/E3aJ7TP.jpg";

require('@solana/wallet-adapter-react-ui/styles.css');
const wallets = [getPhantomWallet(), getSolflareWallet(), getSolletWallet(), getSlopeWallet()];

function App() {
  const [ButtonConstants, setButtonConstants] = useState(constants.BUTTONS);
  const [whaleMode, setWhaleMode] = useState(false);
  const wallet = useWallet();
  const [page, setPage] = useState();
  const [price, setPrice] = useState(null)
  const [side, setSide] = useState("H")
  const [winner, setWinner] = useState(null)
  const [degenInstance, setDegenInstance] = useState()
  const [showSeeResult, setSeeResult] = useState()
  const showMessage = () => { return true; };
  const [updated, setUpdated] = useState()
  const [history, setHistory] = useState([]);
  const [manualDegenId, setManualDegenId] = useState('');
  const [showStuckLoading, setShowStuckLoading] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [referenceId, setReferenceId] = useState();
  const [auth, setAuth] = useState(loadState());
  const [homeLoading, setHomeLoading] = useState(false);
  const [rewardLoading, setRewardLoading] = useState(false);
  const [showRewardButton, setShowRewardButton] = useState(false);
  const [showStuckFlippingButton, setShowStuckFlippingButton] = useState(false);
  const [flipResult, setFlipResult] = useState();
  const [recentFlips, setRecentFlips] = useState([]);
  const [topFlips, setTopFlips] = useState([]);
  const [currentWinStreak, setCurrentWinStreak] = useState();
  const [profile, setProfile] = useState({});
  const [coinFlip, setCoinFlip] = useState();
  const [style, setStyle] = useState(localStorage.getItem('style') ?? PRIMARY_THEME);
  const [acceptedTerms, setAcceptedTerms] = useState(localStorage.getItem('terms'));
  const [toggle] = useMultiAudio()
  const [muted, setMuted] = useState(false);
  const [referralCode, setReferralCode] = useState(new URL(window.location.href).searchParams.get('ref'));

  const [showPleaseWaitButton, setShowPleaseWaitButton] = useState(false);
  const [isEligible, setIsEligible] = useState(false);
  const [coinFlipId, setCoinFlipId] = useState(null);

  const toggleTheme = () => {
    if (style === PRIMARY_THEME) {
      setStyle(SECONDARY_THEME)
      localStorage.setItem('style', SECONDARY_THEME)
    } else {
      setStyle(PRIMARY_THEME)
      localStorage.setItem('style', PRIMARY_THEME)
    }
  }

  const [showProfileModal, setShowProfileModal] = useState(false);
  const handleProfileModalClose = () => setShowProfileModal(false);
  const handleProfileModalOpen = () => setShowProfileModal(true);

  const [showResponsible, setShowResponsible] = useState(false);
  const handleResponsibleClose = () => setShowResponsible(false);
  const handleResponsibleOpen = () => setShowResponsible(true);

  const [showFAQ, setShowFAQ] = useState(false);
  const handleFAQClose = () => setShowFAQ(false);
  const handleFAQOpen = () => setShowFAQ(true);


  const [showHowToPlay, setShowHowToPlay] = useState(false);
  const handleHowToPlayClose = () => setShowHowToPlay(false);
  const handleHowToPlayOpen = () => setShowHowToPlay(true);

  const [showSlowTPS, setShowSlowTPS] = useState(false);
  const handleSlowTPSClose = () => setShowSlowTPS(false);
  const handleSlowTPSOpen = () => setShowSlowTPS(true);

  const [showTerms, setShowTerms] = useState(false);
  const handleTermsClose = () => setShowTerms(false);
  const handleTermsOpen = () => setShowTerms(true);

  const [showPlayersCard, setShowPlayersCard] = useState(false);
  const handlePlayersCardClose = () => setShowPlayersCard(false);
  const handlePlayersCardOpen = () => setShowPlayersCard(true);

  const [showNotificationsModal, setShowNotificationsModal] = useState(false);
  const handleNotificationsModalClose = () => setShowNotificationsModal(false);
  const handleNotificationsModalOpen = () => setShowNotificationsModal(true);

  const [showBreakModal, setShowBreakModal] = useState(false);
  const handleBreakModalClose = () => setShowBreakModal(false);
  const handleBreakModalOpen = () => setShowBreakModal(true);

  const [showStuckModal, setShowStuckModal] = useState(false);
  const handleStuckModalClose = () => setShowStuckModal(false);
  const handleStuckModalOpen = () => setShowStuckModal(true);

  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const handleCongratsModalClose = () => setShowCongratsModal(false);
  const handleCongratsModalOpen = () => setShowCongratsModal(true);

  const [toastShow, setToastShow] = useState(false);
  const [errorToastShow, setErrorToastShow] = useState(false);
  const [reclaimedToastShow, setReclaimedToastShow] = useState(false);
  const [alreadyClaimedToastShow, setAlreadyClaimedToastShow] = useState(false);
  const [youLostToastShow, setYouLostToastShow] = useState(false);
  const [youWonToastShow, setYouWonToastShow] = useState(false);

  const [genericToastShow, setGenericToastShow] = useState(false);
  const [genericError, setGenericError] = useState();

  const [showBalance, setShowBalance] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [loadedProfile, setProfileLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      if (
        !wallet ||
        !wallet.publicKey
      ) {
        return;
      }

      const publicKey = wallet.publicKey.toString();
      try {
        const profile = await getProfile(publicKey);
        setProfile(profile);
      } catch {

      }
      setProfileLoaded(true);

      if (!localStorage.getItem('mobile_event')) {
        setTimeout(() => {
          localStorage.setItem('mobile_event', true)
          handlePlayersCardOpen();
        }, 900);
      }

    })();
  }, [wallet]);

  useEffect(async () => {
    // console.log(window.location);
    const url = window.location.hostname;
    if (url?.startsWith('degencoinflip.com')) return;
    let newUrl = NEW_URL_MAP[url];
    if (newUrl?.startsWith('https://degencoinflip.com')) {
      window.location.replace(newUrl);
    }
    else if (newUrl?.includes('mixpanel')) {
      window.location.replace(newUrl);
    }
  }, []);

  const getRecentFlips = async (limit = 12) => setRecentFlips(await queryLatestCoinFlips(DateTime.utc().minus({ hours: 1 }), limit));
  const getTopFlips = async (limit) => {
    const topFlips = await queryTopCoinFlips(DateTime.utc().minus({ days: 10 }), 5, limit, CURRENT_CHALLENGE.id);
    if (topFlips?.length) {
      setTopFlips(topFlips?.slice(0, 10));
    }
  }


  const claimRewards = async () => {
    const hasReward = await rewardExists(wallet);
    if (!hasReward) {
      // setGenericError(ERRORS.REWARD_PROCESSING);
      // setGenericToastShow(true);
      // await getCoinFlip(wallet?.publicKey?.toString(), auth?.idToken);
      setShowPleaseWaitButton(true);
      return;
    }

    setRewardLoading(true);
    try {
      await getRewards(wallet);
      setRewardLoading(false);
      handleSetCurrentBalance();
      resetAll();
      setYouWonToastShow(true);
    } catch {
      setRewardLoading(false);
      setGenericError(ERRORS.REWARD_PROCESSING);
      setGenericToastShow(true);
    }
  }

  const resetAll = () => {
    setPage("dubornut");
    setWinner(null);
    setDegenInstance(null);
    setSeeResult(false)
    setShowStuckLoading(false)
    setReferenceId(null);
    setShowRewardButton(false);
    setFlipResult(null);
  }

  const retryFlip = async () => {
    setShowStuckFlippingButton(false);
    await depositAndCreateCoinFlip(side, price);
  }

  const depositAndCreateCoinFlip = async (selectedSide, price, prevWinStreak = currentWinStreak) => {
    setSide(selectedSide);
    setPrice(price);
    setShowStuckFlippingButton(false);

    const workflowParams = {
      headers: {
        token: auth?.idToken
      },
      deposit: {
        amount: price
      },
      create: {
        side: selectedSide,
        challengeId: CURRENT_CHALLENGE.id,
        mode: whaleMode ? "WHALE" : "DEFAULT",
        isMobile
      }
    };

    setPage("flip");
    let response;
    try {
      response = await runWorkflow(createCoinFlipWorkflowSteps, workflowParams, wallet);
    } catch (error) {
      console.log(error);
      setGenericError(error);
      if (error?.code === ERRORS.DEPOSIT_FAILED.code) {
        setGenericToastShow(true);
        setPage("dubornut")
      }
      if (error?.code === ERRORS.PREV_PROCESSING.code) {
        setGenericToastShow(true);
        setPage("dubornut")
        // setSide(error?.coinFlip?.side ?? side);
        // setPrice(error?.coinFlip?.amount ?? price);
        // setWinner(error?.coinFlip?.won ? error?.coinFlip?.side : getOppositeSide(error?.coinFlip?.side));
        // setPage("result")
      }
      else {
        setGenericToastShow(true);
        setShowStuckFlippingButton(true);
      }
      return;
    }


    const {
      rewardsSkip,
      create: coinFlip
    } = response;

    setSide(coinFlip?.side ?? side);
    setPrice(coinFlip?.amount ?? price);

    if (coinFlip?.id) {
      setCoinFlipId(coinFlip?.id);
    }

    if (coinFlip?.processing) {
      setGenericError(ERRORS.PREV_PROCESSING);
      setGenericToastShow(true);
      setPage("dubornut");
      return;
    }

    if (rewardsSkip) {

      // state
      setWinner(selectedSide);

      if (prevWinStreak > 1) {
        // confetti
        confetti.start();
        setTimeout(function () {
          confetti.stop()
        }, 5000);
        if (prevWinStreak == 2) {
          playSoundFx(3);
        } else {
          playSoundFx(4);
        }
      } else {
        playSoundFx(2);
      }

      // change page
      setPage("result");
      return;
    }

    setShowPleaseWaitButton(true);
    if (coinFlip?.id) {
      setCurrentWinStreak(0);
      setWinner(selectedSide == "H" ? "T" : "H");

      if (coinFlip?.won) {
        setWinner(selectedSide);

        if (coinFlip?.winStreak > 1) {
          setCurrentWinStreak(coinFlip?.winStreak);
          // confetti
          confetti.start();
          setTimeout(function () {
            confetti.stop()
          }, 5000);
          if (coinFlip?.winStreak == 2) {
            playSoundFx(3);
          } else {
            playSoundFx(4);
          }
        } else {
          playSoundFx(2);
        }
      }
      else if (!coinFlip?.processing) {
        setYouLostToastShow(true);
        if (prevWinStreak > 1) {
          playSoundFx(1);
        } else {
          playSoundFx(0);
        }
      }

      setPage("result");
    }
  }

  const beginFlipping = async () => {
    if (!wallet.connected) return;
    const balance = await getCurrentBalance(wallet);
    setCurrentBalance(balance);
    setHomeLoading(true);

    let accepted = acceptedTerms ?? true;
    let authResponse;
    // if (!auth || DateTime.fromSeconds(auth?.exp) < DateTime.utc()) {
    //   const nonceResponse = await getNonce(wallet?.publicKey?.toString(), referralCode);
    //   const nonce = nonceResponse?.nonce;
    //   accepted = nonceResponse?.acceptedTerms;
    //   let signedMessage;
    //   try {
    //     signedMessage = await signMessage(nonce);
    //   } catch {
    //     setHomeLoading(false);
    //     return;
    //   }

    //   authResponse = await authorize(wallet?.publicKey?.toString(), signedMessage.signature);
    //   saveState(authResponse);
    //   setAuth(authResponse);
    // } else {
    //   authResponse = auth;
    // }

    let existingCoinFlip;
    try {
      existingCoinFlip = await getCoinFlip(wallet?.publicKey?.toString(), authResponse?.idToken);
      // console.log(existingCoinFlip.tx ?? existingCoinFlip.id);
      setCoinFlip(existingCoinFlip);
      setCoinFlipId(existingCoinFlip.id);
    } catch {

    }

    if (existingCoinFlip && existingCoinFlip?.status != CoinFlipStatus.FINALIZED) {
      // setReclaimedToastShow(true);
      setCurrentWinStreak(existingCoinFlip?.winStreak);
      await depositAndCreateCoinFlip(existingCoinFlip?.side, existingCoinFlip?.amount, existingCoinFlip?.winStreak)
    } else {
      setPage("dubornut")
      if (!accepted) {
        setTimeout(() => {
          handleTermsOpen();
        }, 690);
      } else {
        setTimeout(() => {
          handleSlowTPSOpen();
        }, 690);
      }
    }

    handleSetCurrentBalance();

    // if (!isEligible) {
    //   const eligible = await getWave1Eligibility(wallet?.publicKey?.toString());
    //   setIsEligible(eligible);
    // }
  }

  const BalanceStatusInfo = () => {
    const DEFAULT_INTERVAL = 5000;
    useInterval(async () => {
      if (wallet) {
        const balance = await getCurrentBalance(wallet);
        setCurrentBalance(balance);
      }
    }, DEFAULT_INTERVAL);

    return (
      <h5 className="mt-1 balance-text mb-0">SOL {currentBalance.toFixed(5)} </h5>
    )
  }

  const handleSetCurrentBalance = async () => {
    setShowBalance(true);
  }

  const playSoundFx = (key) => {
    if (muted) return;
    toggle(key);
  }

  const Modals = () => {
    return (
      <>
        {
          showProfileModal &&
          <ProfileModal
            show={showProfileModal}
            wallet={wallet}
            profile={profile}
            defaultProfilePicture={DEFAULT_PFP}
            onHide={handleProfileModalClose}
            setProfile={setProfile}
            styleCss={style}
          />
        }
        {
          showResponsible &&
          <ResponsibleModal
            show={showResponsible}
            onHide={handleResponsibleClose}
            styleCss={style}
          />
        }
        {
          showSlowTPS &&
          <SlowTPSModal
            show={showSlowTPS}
            onClose={() => handleSlowTPSClose()}
            styleCss={style}
          />
        }
        {
          showFAQ &&
          <FAQModal
            show={showFAQ}
            onHide={() => handleFAQClose()}
            wallet={wallet}
            styleCss={style}
          />
        }
        {
          showHowToPlay &&
          <HowToPlayModal
            show={showHowToPlay}
            onHide={() => handleHowToPlayClose()}
            styleCss={style}
          />
        }
        {
          showTerms &&
          <AcceptTermsModal
            show={showTerms}
            onClose={() => handleTermsClose()}
            styleCss={style}
          />
        }
        {
          showPlayersCard &&
          <PlayersCardModal
            show={showPlayersCard}
            onClose={() => handlePlayersCardClose()}
            styleCss={style}
          />
        }
        {
          showBreakModal &&
          <TakeABreakModal
            show={showBreakModal}
            onHide={() => handleBreakModalClose()}
            styleCss={style}
          />
        }
        {
          showStuckModal &&
          <StuckModal
            show={showStuckModal}
            onHide={() => handleStuckModalClose()}
            styleCss={style}
          />
        }
        {
          showCongratsModal &&
          <CongratsModal
            show={showCongratsModal}
            onClose={() => handleCongratsModalClose()}
            styleCss={style}
          />
        }
        {/* {
          showNotificationsModal &&
          <NotificationsModal
            show={showNotificationsModal}
            wallet={wallet}
            used={isEligible?.used}
            onHide={() => handleNotificationsModalClose()}
            onSuccess={() => {handleNotificationsModalClose(); setToastShow(true);}}
            styleCss={style}
          />
        } */}
      </>
    );
  }

  const Toasts = () => {
    return (
      <>
        <ToastContainer position="top-start" className="p-3 top-index">
          <Toast className="d-inline-block m-1" onClose={() => setGenericToastShow(false)} show={genericToastShow} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto"><span className="fas fa-ban text-danger"></span> {genericError?.type}</strong>
            </Toast.Header>
            <Toast.Body>
              {genericError?.message}
            </Toast.Body>
          </Toast>
        </ToastContainer>
        <ToastContainer position="top-start" className="p-3 top-index">
          <Toast onClose={() => setToastShow(false)} show={toastShow} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">Successfully redeemed WL token for Wave 1 - Minting on 1/19 8PM UTC</strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
        <ToastContainer position="top-start" className="p-3 top-index">
          <Toast onClose={() => setErrorToastShow(false)} show={errorToastShow} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">You're broke! Not enough SOL.</strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
        <ToastContainer position="top-start" className="p-3 top-index">
          <Toast onClose={() => setAlreadyClaimedToastShow(false)} show={alreadyClaimedToastShow} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">Solana is still processing your previous coin flip.</strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
        <ToastContainer position="top-start" className="p-3 top-index">
          <Toast onClose={() => setReclaimedToastShow(false)} show={reclaimedToastShow} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">Showing your previous coin flip.</strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
        <ToastContainer position="top-start" className="p-3 top-index">
          <Toast onClose={() => setYouWonToastShow(false)} show={youWonToastShow} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">You doubled your money. Congrats degen.</strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
        <ToastContainer position="top-start" className="p-3 top-index">
          <Toast onClose={() => setYouLostToastShow(false)} show={youLostToastShow} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">Better luck next time fucker.</strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
      </>
    );
  }

  const ThemeToggler = () => {
    return (
      <button className={"ms-2 btn btn-outline-dark" + (style === "dark-black" ? " d-none" : "")} onClick={() => toggleTheme()}>
        {style === SECONDARY_THEME ? SECONDARY_THEME.toLocaleUpperCase() : PRIMARY_THEME.toLocaleUpperCase()} <i className={"fa-xs " + (style === "dark" ? "fas fa-moon" : "far fa-sun")}></i>
      </button>
    );
  }

  const LeaderboardSection = () => {
    const DEFAULT_INTERVAL = 5000;
    useInterval(async () => {
      await getRecentFlips(12);
    }, DEFAULT_INTERVAL);

    return (
      <>
        {/* <div className="mt-3">
        <ul className="list-group leaderboard-list border">
          <li className="list-group-item d-flex flex-row justify-content-start">
            <button onClick={() => { setLeaderboardType('new') }} className={`btn btn${leaderboardType == 'new' ? '' : '-outline'}-dark me-2`}>RECENT</button>
            <button onClick={() => setLeaderboardType('top')} className={`btn btn${leaderboardType == 'top' ? '' : '-outline'}-dark me-2`}>TOP WINS</button>
            <button type="button" className={"btn-close ms-auto my-auto " + (style === 'dark' ? "btn-close-white" : "btn-close")} aria-label="Close" onClick={() => setShowLeaderboard(false)}></button>
          </li>
        </ul>
      </div> */}
        <ul className="list-group mt-1 leaderboard-list border">
          {
            leaderboardType == 'new' && recentFlips?.map(flip => {
              return (<FlipRow flip={flip} key={flip.id} defaultProfilePicture={DEFAULT_PFP} slug={CURRENT_CHALLENGE.slug}></FlipRow>)
            })
          }
          {
            leaderboardType == 'top' && topFlips.map(flip => {
              return (<FlipRow flip={flip} type={`winStreak`} key={flip.id} defaultProfilePicture={DEFAULT_PFP} slug={CURRENT_CHALLENGE.slug}></FlipRow>)
            })
          }
        </ul>
      </>

    );
  }

  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [leaderboardType, setLeaderboardType] = useState("new");
  const TopRightBar = () => {
    return (
      <div className="social-icons">
        <div className="d-flex flex-row flex-sm-column justify-content-start align-items-center h-100">
          <div className="mt-3 d-flex flex-column shortcut-row">

            <div className="d-flex flex-row mb-2 toolbar">
              {
                <>
                  <a
                    href="#"
                    className="ms-2">
                    <button
                      className="btn btn-dark"
                      onClick={async () => {
                        if (!showLeaderboard) {
                          await getRecentFlips(12)
                          setShowLeaderboard(true);
                          setLeaderboardType('new');
                        }
                        else if (showLeaderboard && leaderboardType === 'top')
                          setLeaderboardType('new');
                        else setShowLeaderboard(false)
                      }}>
                      <span className="d-none d-sm-inline-flex">LIVE </span>
                      PLAYS <i className="d-none d-sm-inline-flex fas fa-caret-down fa-xs"></i>
                    </button>
                  </a>
                  <a
                    href="#"
                    className="ms-2">
                    <button
                      className="btn btn-dark"
                      onClick={() => {
                        if (!showLeaderboard) {
                          setShowLeaderboard(true);
                          setLeaderboardType('top');
                        }
                        else if (showLeaderboard && leaderboardType === 'new')
                          setLeaderboardType('top');
                        else setShowLeaderboard(false)
                      }}>
                      <span className="d-none d-sm-inline-flex">WIN </span>
                      STREAKS <i className="d-none d-sm-inline-flex fas fa-trophy fa-xs"></i>
                    </button>
                  </a>
                </>
              }
              <a
                href={`https://leaderboard.degencoinflip.com/${CURRENT_CHALLENGE.slug}`}
                target="_blank" rel="noopener noreferrer"
                className="ms-2">
                <button className="btn btn-dark">
                  LEADERBOARD <i className="d-none d-sm-inline-flex fas fa-external-link-alt fa-xs"></i>
                </button>
              </a>
              {
                wallet?.publicKey?.toString() != null &&
                <>
                  <div className="ms-3 profile-picture-md">
                    <img className={`image rounded-circle cursor-pointer border border-2`}
                      src={profile?.profileImageUrl ?? DEFAULT_PFP}
                      alt={''}
                      onClick={() => handleProfileModalOpen()}
                    />
                  </div>
                </>
              }
            </div>
            {
              showBalance &&
              <BalanceStatusInfo></BalanceStatusInfo>
            }
            {
              showLeaderboard &&
              <>
                <LeaderboardSection></LeaderboardSection>
              </>
            }
          </div>
        </div>
      </div>
    );
  }

  const BottomRightBar = () => {
    return (
      <div className="social-icons-bottom-right">
        <div className="d-flex flex-row flex-sm-column justify-content-start align-items-center h-100">
          <div className="mt-3 d-flex flex-column shortcut-row">
            <SocialLinks social={CURRENT_CHALLENGE.social}></SocialLinks>
          </div>
        </div>
      </div>
    );
  }

  const TopLeftBar = () => {
    return (
      <div className="social-icons-left">
        <div className="d-flex flex-row flex-sm-column justify-content-start align-items-center h-100">
          <div className="mt-3 d-flex flex-column shortcut-row">
            <div className="d-flex flex-row mb-2 toolbar">
              <AudioButton muted={muted} setMuted={setMuted}></AudioButton>
              {
                !showChat && wallet?.publicKey?.toString()?.length > 0 &&
                <button className="btn btn-outline-dark d-block d-sm-flex ms-2" onClick={() => setShowChat(true)}>
                  <i className={"fas my-auto fa-comment-alt"}></i>
                </button>
              }
              <ThemeToggler></ThemeToggler>
              {/* {
                wallet?.publicKey?.toString() != null && isEligible &&
                <button className="btn btn-outline-dark d-flex h-100 ms-2 position-relative" onClick={() => handleNotificationsModalOpen()}>
                  <span className="fas fa-bell my-auto"></span>
                  {
                    isEligible?.used == 0 &&
                    <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                      <span className="visually-hidden">New alerts</span>
                    </span>
                  }
                </button>
              } */}
            </div>
          </div>
        </div>
      </div>
    );
  }


  const MultiCoinFlipAlert = () => {
    return (
      <label className="list-group-item py-3 container border mb-5">
        <div className="row g-0 text-start">
          <div className="col-8">
            <h5 className="mb-0 position-relative">WHALE MODE
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                NEW
                <span className="visually-hidden">beta</span>
              </span>
            </h5>
            <span className="d-block small opacity-50">Up to 3 SOL bets.</span>
          </div>
          <div className="col d-flex">
            <button className="btn-sm btn btn-dark w-100 my-auto" onClick={() => easterEgg()}>TRY NOW</button>
          </div>
        </div>
      </label>)
  };

  const SingleCoinFlipAlert = () => {
    return (
      <label className="list-group-item py-3 container border mb-5">
        <div className="row g-0 text-start">
          <div className="col d-flex">
            <button className="btn-sm btn btn-dark w-100 my-auto" onClick={() => setPage("dubornut")}>GO BACK</button>
          </div>
          <div className="col-8">
            <h5 className="mb-0 ms-3 position-relative">BABY MODE</h5>
            <span className="ms-3 d-block small opacity-50">Baby bets.</span>
          </div>
        </div>
      </label>)
  };

  const easterEgg = () => {
    setWhaleMode(true);
    handleSetCurrentBalance();
    setPage("dubornut");
    setStyle("dark-black")
    setButtonConstants(constants.BUTTONS_WHALE);
    // setTimeout(() => {
    //   alert('Welcome to WHALE mode. Good luck & have fun.');
    // }, 500);
  }

  const [degenBalance, setDegenBalance] = useState(null);
  const [rewardsBalance, setRewardsBalance] = useState(null);

  const checkForMyFunds = async () => {
    const db = await getDegenBalance(wallet);
    console.log(db);
    setDegenBalance(db);

    const rb = await getRewardsBalance(wallet);
    console.log(rb);
    setRewardsBalance(rb);
  }

  const withdrawFunds = async () => {
    const tx = await withdrawDegen(wallet);
    console.log(tx);
  }

  return (
    <React.Fragment>
      <div className={style}>

        <div className={"text-center body-wrapper h-100vh h-100 main-header" + (CURRENT_CHALLENGE?.backgroundImage?.length ? ' bg-image' : '')} style={{ backgroundImage: `url(${CURRENT_CHALLENGE?.backgroundImage}` }}>

          <div className="play step2">
            <div className="form-signin">
              <>
                <img className={CURRENT_CHALLENGE.coinProcessingImageUrlIsSquare ? "coin-flipping-square" : "coin-flipping"} src={CURRENT_CHALLENGE.coinProcessingImageUrl ?? constants.BUTTONS.Processing} alt="processing" />
                <h3 className="mt-3">WELCOME DEGEN</h3>
              </>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}



const AppWithProvider = () => (
  <ConnectionProvider endpoint="http://127.0.0.1:8899">
    <WalletProvider wallets={wallets} autoConnect>
      <WalletModalProvider>
        <App />
      </WalletModalProvider>
    </WalletProvider>
  </ConnectionProvider>
)

export default AppWithProvider;