import { getCoinFlip } from "../../api/degen.service";
import { CoinFlipStatus, ERRORS } from "../../utils/constants";

export const handler = async (event) => {
    const {
        headers: {
            token
        },
        solana: {
            provider
        }
    } = event;

    let existingCoinFlip;
    try {
        existingCoinFlip = await getCoinFlip(provider.wallet.publicKey.toString(), token);
    }
    catch {
        // console.log('no existing coin flip');
    }

    if (existingCoinFlip?.status === CoinFlipStatus.FINALIZE_REQUESTED) {
        throw { ...ERRORS.PREV_PROCESSING, coinFlip: existingCoinFlip };
    }

    return {
        ...event
    };
}