import { Idl, Program, BN, web3, utils } from '@project-serum/anchor';
import { getSolanaProvider } from "../../services/solana";
import idl from '../../idl_dcf.json';
import { LAMPORTS_PER_SOL, SYSVAR_INSTRUCTIONS_PUBKEY } from '@solana/web3.js';
import { getDegenCoinFlipDegenerateAccount, getDegenCoinFlipHouseState, getDegenCoinFlipHouseTreasury, getDegenCoinFlipRewardsAccount } from '../../utils/accounts';
import { INITIALIZER_ID, AUTHORITY_ID } from '../../utils/program-constants';
import moize from 'moize';
import { ERRORS } from '../../utils/constants';

const MMCC_CHALLENGE_ID = "2e40f832-9c3f-4c90-b208-43d37afcab95";

const {
  SystemProgram,
  Keypair,
  SYSVAR_RENT_PUBKEY,
  PublicKey,
  SYSVAR_CLOCK_PUBKEY
} = web3;

let programID: any;
let program: any;
let provider: any;

const init = moize((wallet: any = null) => {
  if (program) return;
  programID = new PublicKey(idl.metadata.address);
  provider = getSolanaProvider(wallet);
  program = new Program(idl as Idl, programID, provider);
});

const getProvider = moize(() => {
  if ("solana" in window) {
    const anyWindow: any = window;
    const provider = anyWindow.solana;
    if (provider.isPhantom) {
      return provider;
    }
  }
  window.open("https://phantom.app/", "_blank");
});


export const handler = async (event: any) => {
  const {
    solana: {
      provider,
      program
    },
    degenBalance: {
      skip
    },
    rewardsSkip,
    deposit: {
      amount
    }
  } = event;
  if (skip || rewardsSkip) return event;

  // init(wallet);

  const [_house_treasury_account_pda, _house_treasury_account_bump] = await getDegenCoinFlipHouseTreasury(
    INITIALIZER_ID, AUTHORITY_ID
  );

  const [_house_state_account_pda, _house_state_account_bump] = await getDegenCoinFlipHouseState(
    INITIALIZER_ID, AUTHORITY_ID
  );

  const [_degenerate_account_pda, _degenerate_account_bump] = await getDegenCoinFlipDegenerateAccount(
    provider.wallet.publicKey, INITIALIZER_ID, AUTHORITY_ID
  );

  const [_rewards_account_pda, _rewards_account_bump] = await getDegenCoinFlipRewardsAccount(
    provider.wallet.publicKey, INITIALIZER_ID, AUTHORITY_ID
  );

  let tx;
  try {
    tx = await program.rpc.goDegen(
      _house_treasury_account_bump,
      _house_state_account_bump,
      _degenerate_account_bump,
      _rewards_account_bump,
      new BN(amount * LAMPORTS_PER_SOL),
      {
        accounts: {
          degenerate: provider.wallet.publicKey,
          initializer: INITIALIZER_ID,
          authority: AUTHORITY_ID,
          houseTreasury: _house_treasury_account_pda,
          houseState: _house_state_account_pda,
          degenerateAccount: _degenerate_account_pda,
          rewardsAccount: _rewards_account_pda,
          systemProgram: SystemProgram.programId,
          instructions: SYSVAR_INSTRUCTIONS_PUBKEY
        }
      }
    );
  }
  catch (e) {
    throw ERRORS.DEPOSIT_FAILED;
  }

  return {
    ...event,
    headers: {
      ...event.headers,
      walletId: provider.wallet.publicKey.toString()
    },
    deposit: {
      ...event.deposit,
      tx
    },
  };
};
