import { PublicKey } from "@solana/web3.js";

export const HOUSE_TREASURY = 'house_treasury';
export const HOUSE_STATE = 'house_state';
export const DEGENERATE = 'degenerate';
export const REWARDS = 'rewards';
export const DEGEN_COIN_FLIP = 'degen_coin_flip';
export const DEGEN_COIN_FLIP_PROGRAM_ID = new PublicKey(
  'BU6VD7ASxAcAfvAZCxQH6Rqm8RNgdj5t7Xkh4EsQtK8U',
);

export const INITIALIZER_ID = new PublicKey(
  'Fe77Txknt6mLx52wNq58TJ9Arwe6oEyDHUAaG7c1xo5',
);

export const AUTHORITY_ID = new PublicKey(
  'CfBFQhbKXyKt7zr8h3PoYRvgZVbXAGpYzvxM6M4cJxTN',
);