


import axios from "axios";
import { get, post } from "../utils/http.util";

// const BASE_URL = 'http://localhost:3000';
const BASE_URL = 'https://pol355ivn9.execute-api.us-east-1.amazonaws.com/prod';

export default async function handler(req: any) {
  const { wallet, won, amount, side, winner, houseAmount, degenInstanceId } = req;
  const VERIFY_URL = `${BASE_URL}/degenerates/message?wallet=${wallet}&won=${won}&amount=${amount}&side=${side}&winner=${winner}&houseAmount=${houseAmount}&degenInstanceId=${degenInstanceId}`;
  const { data }: any = await axios(VERIFY_URL, {
    method: "GET"
  });
  return data;
}

export async function log(req: any) {
  const { wallet, amount, side, degenInstanceId } = req;
  const VERIFY_URL = `${BASE_URL}/degenerates/log?wallet=${wallet}&amount=${amount}&side=${side}&degenInstanceId=${degenInstanceId}`;
  const { data }: any = await axios(VERIFY_URL, {
    method: "GET"
  });
  return data;
}


export async function getHistory(walletId: string) {
  const VERIFY_URL = `${BASE_URL}/degen_instances?wallet=${walletId}`;
  const { data }: any = await axios(VERIFY_URL, {
    method: "GET"
  });
  return data;
}


export async function flipCoin(walletId: string) {
  const VERIFY_URL = `${BASE_URL}/wallets/${walletId}/flip`;
  const { data }: any = await axios(VERIFY_URL, {
    method: "GET"
  });
  return data;
}


export const startCoinFlip = async (coinFlip: any, Authorization: any) => {
  const url = `${BASE_URL}/wallets/${coinFlip.walletId}/flips/init`
  const response  = await post(url, coinFlip, { Authorization });
  return response?.data;
}

export const finalizeCoinFlip = async (walletId: any, side: any, Authorization: any) => {
  let url = `${BASE_URL}/wallets/${walletId}/flips/finalize`;
  if (side) {
    url = url + '?side='+side;
  }
  const response  = await get(url, { Authorization });
  return response?.payload;
}


export const finalizeCoinFlipV2 = async (walletId: any, createdAt: any, side: any, Authorization: any) => {
  let url = `${BASE_URL}/wallets/${walletId}/flips/finalizeV2?createdAt=${createdAt}`;
  if (side) {
    url = url + '&side='+side;
  }
  const response  = await get(url, { Authorization });
  return response?.payload;
}

export const viewReward = async (walletId: any, Authorization: any) => {
  let url = `${BASE_URL}/wallets/${walletId}/flips/reward`;
  const response  = await get(url, { Authorization });
  return response?.payload;
}


export const getCoinFlip = async (walletId: any, Authorization: any) => {
  const url = `${BASE_URL}/wallets/${walletId}/coinFlips/latest`
  const response  = await get(url, { Authorization });
  // console.log(response);
  return response?.payload;
}

export const getRecentCoinFlip = async (walletId: any, Authorization: any) => {
  const url = `${BASE_URL}/wallets/${walletId}/coin-flip`
  const response  = await get(url, { Authorization });
  // console.log(response);
  return response?.payload;
}

export const getNonce = async (walletId: any, referral: string) => {
  let url = `${BASE_URL}/wallets/${walletId}/nonce`
  if (referral?.length) {
    url = url + `?referral=${referral}`;
  }
  const response  = await get(url);
  // console.log(response);
  return response?.payload;
}

export const authorize = async (walletId: any, signature: any) => {
  const url = `${BASE_URL}/authorize`
  const { data }  = await post(url, { walletId, signature });
  return data?.payload;
}

export const createCoinFlip = async (coinFlip: any, Authorization: any) => {
  const url = `${BASE_URL}/coinFlips`
  const { data }  = await post(url, { ...coinFlip }, { Authorization });
  return data?.payload;
}