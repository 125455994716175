// export const RPC_URL = "https://ssc-dao.genesysgo.net";
export const RPC_URL = "https://morning-silent-water.solana-mainnet.quiknode.pro/7875e7e2ecd127f9799d91fe76564d2977b54b50/";

export const faqs = [
  { q: "What is Degen Coin Flip (DCF)?", a: "Degen Coin Flip is a smart contract that allows users to play Double or Nothing with their Solana tokens. Odds are 50/50 with a 3.5% fee that goes to DCF NFT holders." },
  { q: "How do I know I can Trust DCF?", a: "DCF has over 420K SOL (~50 Million USD) flipped since we started and we are the trusted platform on Solana.\n\nThe DCF Team and DCF NFT holders' have aligned incentives for the game to have exactly 50/50 odds.\n\nOur House and Fee wallets are all public and every transaction can be reviewed by anyone." },
  { q: "Where can I track transactions?", a: "House Wallet:\nhttps://explorer.solana.com/address/DLq9BPETifWi56sxmW29FVCYGhpJSupq9v6uC5cYxgQA\n\nFee Wallet:\nhttps://explorer.solana.com/address/Fe77Txknt6mLx52wNq58TJ9Arwe6oEyDHUAaG7c1xo5" },
  { q: "Where can I learn more?", a: "Join us on discord, there will always be someone to help you out.\nhttps://discord.gg/f5JGjGysHU" },
];

export const CHALLENGES = [
  {
    "id": "00000000-0000-0000-0000-000000042069",
    "coinImageUrl": "https://i.imgur.com/JtLE64h.png",
    "logoUrl": "https://i.imgur.com/896fn7R.png",
    "name": "Degen",
    "profileImageUrl": "https://i.imgur.com/WtWzHUL.png",
    "url": "https://degencoinflip.com",
    "themes": ["light", "dark"],
    "social": {
      "twitterUrl": "https://twitter.com/degencoinflip",
      "marketplaceUrl": "https://magiceden.io/marketplace/degen_coin_flip",
      "discordUrl": "https://discord.gg/EbKC53qVWZ"
    },
    "slug": "wagmi"
  },
  {
    "id": "d2e33f7c-95e5-4125-87e0-ce89b500b836",
    "coinImageUrl": "https://i.imgur.com/uxqwMQZ.png",
    "logoUrl": "https://i.imgur.com/uxqwMQZ.png",
    "name": "SolChicks",
    "profileImageUrl": "https://i.imgur.com/uxqwMQZ.png",
    "url": "https://flip.solchicks.io",
    "themes": ["dark", "light"],
    "social": {
      "twitterUrl": "https://twitter.com/SolChicksNFT",
      "marketplaceUrl": "https://magiceden.io/marketplace/solchicks",
      "discordUrl": "https://discord.gg/solchicks"
    },
    "slug": "solchicks"
  },
  {
    "id": "afb2ea05-24d8-47d9-92e6-2cf659b19883",
    "coinImageUrl": "https://i.imgur.com/b8pipWF.png",
    "logoUrl": "https://i.imgur.com/ciLzClZ.jpg",
    "name": "Degen Dex",
    "profileImageUrl": "https://i.imgur.com/b8pipWF.png",
    "url": "https://flip.degendex.io",
    "themes": ["dark", "light"],
    "slug": "degendex",
    "social": {
      "twitterUrl": "https://twitter.com/DegenDex",
      "marketplaceUrl": "https://magiceden.io/marketplace/degen_coin_flip",
      "discordUrl": "https://discord.gg/3WcY4JjFeB"
    }
  },
  {
    "id": "2e40f832-9c3f-4c90-b208-43d37afcab95",
    "coinImageUrl": "https://i.imgur.com/PcpvtJg.png",
    "logoUrl": "https://c.tenor.com/caKYaPNBiCQAAAAC/meerkat-mmcc.gif",
    "name": "Meerkat Millionaires",
    "profileImageUrl": "https://i.imgur.com/PcpvtJg.png",
    "url": "https://flip.meerkatmillionaires.club",
    "themes": ["dark", "light"],
    "slug": "mmcc",
    "social": {
      "twitterUrl": "https://twitter.com/mmccsolana",
      "marketplaceUrl": "https://www.magiceden.io/marketplace/meerkat_millionaires_country_club",
      "discordUrl": "https://discord.com/invite/MMCC"
    },
  },
  {
    "id": "bf4c00c6-d5c3-411f-a4e9-eaf07c169ba0",
    "coinImageUrl": "https://i.imgur.com/rXTYGMZ.png",
    "logoUrl": "https://i.imgur.com/rXTYGMZ.png",
    "name": "Solana NFT Radar",
    "profileImageUrl": "https://i.imgur.com/rXTYGMZ.png",
    "url": "https://flip.solnftradar.com",
    "themes": ["dark", "light"],
    "social": {
      "twitterUrl": "https://twitter.com/solnftradar",
      "marketplaceUrl": "https://magiceden.io/marketplace/degen_coin_flip",
      "discordUrl": "http://discord.gg/nftradar"
    },
    "slug": "solnftradar"
  },
  {
    "id": "63a44135-31db-44d8-b539-b62262112f9e",
    "coinImageUrl": "https://i.imgur.com/W8iu5dO.png",
    "logoUrl": "https://i.imgur.com/W8iu5dO.png",
    "name": "Doge Capital",
    "profileImageUrl": "https://i.imgur.com/W8iu5dO.png",
    "url": "https://flip.thedogecapital.com ",
    "themes": ["dark", "light"],
    "social": {
      "twitterUrl": "https://twitter.com/thedogecapital",
      "marketplaceUrl": "https://magiceden.io/marketplace/doge_capital",
      "discordUrl": "https://discord.com/invite/DeM5VFF29v"
    },
    "slug": "dogecapital"
  },
  {
    "id": "c458b174-676f-4ab2-a7e7-d677ecc61c22",
    "coinImageUrl": "https://i.imgur.com/mcMfLF9.png",
    "logoUrl": "https://i.imgur.com/mcMfLF9.png",
    "name": "Solana Slugs",
    "profileImageUrl": "https://i.imgur.com/mcMfLF9.png",
    "url": "https://flip.solslugs.com",
    "themes": ["dark", "light"],
    "social": {
      "twitterUrl": "https://twitter.com/SolSlugsNFT",
      "marketplaceUrl": "https://magiceden.io/marketplace/sol_slugs",
      "discordUrl": "discord.gg/TKpFrWwa"
    },
    "slug": "solslugs"
  },
  {
    "id": "01aa6abe-feb1-48ef-a2b0-bc5618ab81a9",
    "coinImageUrl": "https://i.imgur.com/2KcwYfe.png",
    "logoUrl": "https://i.imgur.com/j07P8tg.gif",
    "name": "Bōryoku Dragonz",
    "profileImageUrl": "https://i.imgur.com/2KcwYfe.png",
    "url": "https://tajfeet.io",
    "themes": ["dark", "light"],
    "social": {
      "twitterUrl": "https://twitter.com/boryokudragonz",
      "marketplaceUrl": "https://magiceden.io/marketplace/boryoku_dragonz",
      "discordUrl": "https://discord.gg/jSUWCYm3rF"
    },
    "slug": "BoryokuDragonz"
  },
  {
    "id": "32f5cce2-c9d1-49aa-bf16-e1c24e8fe899",
    "coinImageUrl": "https://i.imgur.com/mv3yDZS.png",
    "logoUrl": "https://i.imgur.com/mv3yDZS.png",
    "name": "Visionary",
    "profileImageUrl": "https://i.imgur.com/mv3yDZS.png",
    "url": "https://flip.visionarystudios.io",
    "themes": ["dark", "light"],
    "backgroundImage": "https://i.imgur.com/dmwMoOc.png",
    "social": {
      "twitterUrl": "http://twitter.com/solanavisionary",
      "marketplaceUrl": "https://magiceden.io/marketplace/degen_coin_flip",
      "discordUrl": "https://discord.gg/visionarystudios"
    },
    "slug": "visionary"
  },
  {
    "id": "1faa21e1-a2d7-45ff-a61b-b2a4fd28c51a",
    "coinImageUrl": "https://i.imgur.com/xzQB0P6.png",
    "logoUrl": "https://i.imgur.com/g4rQfaH.jpg",
    "name": "Electons",
    "profileImageUrl": "https://i.imgur.com/mv3yDZS.png",
    "url": "https://doubleorskulls.com",
    "themes": ["dark", "light"],
    "backgroundImage": "https://i.imgur.com/DOPofOA.jpg",
    "social": {
      "twitterUrl": "https://twitter.com/ElectonsNFT",
      "marketplaceUrl": "https://magiceden.io/marketplace/degen_coin_flip",
      "discordUrl": "https://discord.gg/electons"
    },
    "slug": "electons"
  },
  {
    "id": "9e94a921-9518-48bc-84af-fe66de92218c",
    "logoUrl": "https://i.imgur.com/cgxDli6.jpg",
    "name": "Stoned Ape Crew",
    "profileImageUrl": "https://i.imgur.com/TIIDwy4.jpg",
    "url": "https://sac.degencoinflip.com",
    "themes": ["dark", "light"],
    "social": {
      "twitterUrl": "https://twitter.com/stonedapecrew",
      "marketplaceUrl": "https://magiceden.io/marketplace/stoned_ape_crew",
      "discordUrl": "https://discord.gg/stonedapecrew"
    },
    "slug": "electons",
    "coinImageUrl": "https://i.imgur.com/XgwK4k3.png",
    "coinSideImageUrl": "https://i.imgur.com/F6aXYPK.png",
    "coinProcessingImageUrl": "https://i.imgur.com/zOh4lWc.gif",
    "coinHeadsImageUrl": "https://i.imgur.com/RVWQTb9.gif",
    "coinTailsImageUrl": "https://i.imgur.com/HvkMXbP.gif",
    "coinProcessingImageUrlIsSquare": true,
    "buttonPt05Sol": "https://i.imgur.com/eLrXikn.png",
    "buttonPt10Sol": "https://i.imgur.com/MkIYyk8.png",
    "buttonPt25Sol": "https://i.imgur.com/caOiPw2.png",
    "buttonPt5Sol": "https://i.imgur.com/FFoJs7n.png",
    "button1Sol": "https://i.imgur.com/Qd6DRX9.png",
    "button2Sol": "https://i.imgur.com/5KCQIxO.png",
    "button3Sol": "https://i.imgur.com/QQRtRol.png",
    "buttonTryAgain": "https://i.imgur.com/lljTgbV.png",
    "buttonClaimReward": "https://i.imgur.com/cPlDGOW.png",
    "buttonPleaseWait": "https://i.imgur.com/Rcd5Lmq.png",
    "buttonDoubleOrNothingOptions": "https://i.imgur.com/eGkRWhu.png",
    "buttonDoubleOrNothing": "https://i.imgur.com/70HeIiN.png",
    "buttonTails": "https://i.imgur.com/k1YmK5c.png",
    "buttonHeads": "https://i.imgur.com/H70xdGh.png"
  },
  {
    "id": "fc9ab43e-7da5-4922-8874-4df43ad0dcc8",
    "coinImageUrl": "https://i.imgur.com/ioPQRCo.png",
    "logoUrl": "https://i.imgur.com/ioPQRCo.png",
    "name": "FamilySOL NFT",
    "profileImageUrl": "https://i.imgur.com/ioPQRCo.png",
    "url": "https://familysolnft.com",
    "themes": ["dark", "light"],
    "social": {
      "twitterUrl": "https://twitter.com/familysolnft",
      "marketplaceUrl": "https://t.co/U2C4HHleSg",
      "discordUrl": "https://t.co/Cf2KDOxEUh"
    },
    "slug": "familysol"
  },
  {
    "id": "0d81662e-6844-485e-be58-62334a84feab",
    "coinImageUrl": "https://i.imgur.com/QkgWyfJ.png",
    "logoUrl": "https://i.imgur.com/QkgWyfJ.png",
    "name": "Generous Robots",
    "profileImageUrl": "https://i.imgur.com/QkgWyfJ.png",
    "url": "https://generousrobots.com/",
    "themes": ["dark", "light"],
    "social": {
      "twitterUrl": "https://twitter.com/generousrobots",
      "marketplaceUrl": "https://magiceden.io/marketplace/generous_robots_dao",
      "discordUrl": "https://discord.gg/Ef894r3xFw"
    },
    "slug": "generousrobots"
  },
  {
    "id": "926bee35-20a0-4e26-ae13-2c9968b12bd5",
    "coinImageUrl": "https://i.imgur.com/h344RMl.png",
    "logoUrl": "https://i.imgur.com/h344RMl.png",
    "name": "Moshi Heads",
    "profileImageUrl": "https://i.imgur.com/h344RMl.png",
    "url": "https://www.moshiheads.com/",
    "themes": ["dark", "light"],
    "social": {
      "twitterUrl": "https://twitter.com/Moshiheads",
      "marketplaceUrl": "https://magiceden.io/marketplace/moshiheads_",
      "discordUrl": "http://discord.gg/moshiheads"
    },
    "slug": "moshiheads"
  },
  {
    "id": "ffebf122-08a5-445f-950a-8f6cf2122516",
    "coinImageUrl": "https://i.imgur.com/UsL6Bp1.png",
    "logoUrl": "https://i.imgur.com/UsL6Bp1.png",
    "name": "Chikara Chimps",
    "profileImageUrl": "https://i.imgur.com/UsL6Bp1.png",
    "url": "https://chikara.degencoinflip.com",
    "themes": ["dark", "light"],
    "social": {
      "twitterUrl": "https://twitter.com/chikarachimps",
      "marketplaceUrl": "https://t.co/vHEo4JwCih",
      "discordUrl": "https://t.co/azvNytkU7I"
    },
    "slug": "chikara"
  },
  {
    "id": "871b14e7-c6dc-4f4d-8883-49306c2d98b6",
    "coinImageUrl": "https://i.imgur.com/mwPIumJ.png",
    "logoUrl": "https://i.imgur.com/mwPIumJ.png",
    "name": "MetaFrog Business Club",
    "profileImageUrl": "https://i.imgur.com/mwPIumJ.png",
    "url": "https://flip.metafrogbusinessclub.com/",
    "themes": ["dark", "light"],
    "social": {
      "twitterUrl": "https://twitter.com/MetaFrogBizClub",
      "marketplaceUrl": "https://magiceden.io/marketplace/metafrog_business_club",
      "discordUrl": "https://discord.gg/metafrogsnft"
    },
    "slug": "metafrog"
  }
]

export const constants = {
  LOGO: {
    Default: "https://i.imgur.com/896fn7R.png",
    Whale: "https://i.imgur.com/phpvKTj.png"
    // Default: "https://i.imgur.com/PcpvtJg.png"
  },
  BUTTONS: {
    DoubleOrNothing: "https://i.imgur.com/gMlDK1J.png",
    Yolo: "https://i.imgur.com/kfsT8We.png",
    ClaimReward: "https://i.imgur.com/68rdjJv.png",
    Heads: "https://i.imgur.com/wc7Ci3f.png",
    Tails: "https://i.imgur.com/wxXcGls.png",
    TwoSol: "https://i.imgur.com/eS5gbgf.png",
    OneSol: "https://i.imgur.com/D8b7RNF.png",
    PtOneSol: "https://i.imgur.com/o6IEhiu.png",
    PtFiveSol: "https://i.imgur.com/iChJqD2.png",
    Pt25Sol: "https://i.imgur.com/5lRVCML.png",
    CoinHeads: "https://i.imgur.com/vQqakuK.png",
    CoinTails: "https://i.imgur.com/cFEB8Wg.png",
    ClaimFee: "https://i.imgur.com/rKnaYP9.png",
    BabyCoin: "https://i.imgur.com/AUUGX4z.gif",
    BabyCoinButt: "https://i.imgur.com/pp6kuh9.gif",
    BabyButton: "https://i.imgur.com/LnRn1mC.png",
    TryAgain: "https://i.imgur.com/Qvq0Dvg.png",
    PleaseWait: "https://i.imgur.com/x1WRFzM.png",
    Processing: "https://i.imgur.com/2n0q9IX.gif"
  },
  BUTTONS_WHALE: {
    DoubleOrNothing: "https://i.imgur.com/iei8BiD.png",
    ClaimReward: "https://i.imgur.com/wSyr8pq.png",
    PleaseWait: "https://i.imgur.com/4ZGvTxv.png",
    Heads: "https://i.imgur.com/xSjfP8z.png",
    Tails: "https://i.imgur.com/v3MBBQL.png",
    OneSol: "https://i.imgur.com/mMn3Lpj.png",
    TwoSol: "https://i.imgur.com/2WaNhj6.png",
    ThreeSol: "https://i.imgur.com/KMr8vDX.png"
  },
  GIFS: {
    CoinSide: "https://i.imgur.com/VxoSlqK.gif"
  }
}


export const ERRORS = {
  DEPOSIT_FAILED: {
    code: 1001,
    type: "Solana Network Error",
    message: "Your SOL could not be deposited. Wait a moment, and try again."
  },
  REWARD_PROCESSING: {
    code: 1002,
    type: "Solana Network Error",
    message: "Your SOL reward is still processing. Wait a moment, and try again.",
  },
  FLIP_FAILED: {
    code: 1002,
    type: "Solana Network Error",
    message: "Your deposited SOL is processing. Wait a moment, and try again.",
  },
  PREV_PROCESSING: {
    code: 1003,
    type: "Please Wait",
    message: "Sorry! Solana is still processing your previous coin flip...",
  }
}

export const CoinFlipStatus = {
  STARTED: "STARTED",
  FINALIZED: "FINALIZED",
  FINALIZE_REQUESTED: "FINALIZE_REQUESTED"
};


export const getOppositeSide = (side: any) => {
  if (side === "H") return "T";
  return "H";
}