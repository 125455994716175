import { Idl, Program, BN, web3, utils } from '@project-serum/anchor';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { getSolanaProvider } from "../services/solana";
import idl from '../idl_dcf.json';
import moize from 'moize';
import { getOrCreateAssociatedTokenAccountAddress } from '../utils/tokens';
import { token } from '@project-serum/anchor/dist/cjs/utils';
import { requestWhitelistToken } from '../api/whitelists';

const MINT = "5UpTUkTbtzBcnvkXyfTqyoC6HgJX6r79sT5e8X3U7Tew";

const {
    SystemProgram,
    Keypair,
    SYSVAR_RENT_PUBKEY,
    PublicKey,
    SYSVAR_CLOCK_PUBKEY
} = web3;

let programID: any;
let program: any;
let provider: any;

const init = moize((wallet: any = null, commitment = "processed") => {
    if (program) return;
    programID = new PublicKey(idl.metadata.address);
    provider = getSolanaProvider(wallet, commitment);
    program = new Program(idl as Idl, programID, provider);
});

const getProvider = moize(() => {
    if ("solana" in window) {
        const anyWindow: any = window;
        const provider = anyWindow.solana;
        if (provider.isPhantom && provider.isConnected) {
            return provider;
        }
    }
    if ("solflare" in window) {
        const anyWindow: any = window;
        const provider = anyWindow.solflare;
        if (provider.isSolflare && provider.isConnected) {
            return provider;
        }
    }
    window.open("https://phantom.app/", "_blank", "noopener,noreferrer");
});


export const createTokenAccount = async (wallet: any = null) => {
    init(wallet, "processed");

    const tokenAccount = await getOrCreateAssociatedTokenAccountAddress(
        MINT,
        provider
    );

    // console.log(tokenAccount);
    if (!tokenAccount) return;

    const response = await requestWhitelistToken(provider.wallet.publicKey.toString());
    // console.log(response);
    return response;
};
